
export const adminRoutes = [
    {
        path: '/admin/dashboard',
        component: () => import('@/views/dashboard/Dashboard.vue'),
        name: 'admin_dashboard',
        meta: { title: 'Dashboard', icon: 'user' },
        hidden: false,
        hidden_sub_navbar: false,
    },
    {
        path: '/admin/dashboard/broker/:id',
        component: () => import('@/views/dashboard/BrokerDetails.vue'),
        name: 'admin_dashboard_broker_details',
        meta: { title: 'Dashboard Broker', icon: 'user' },
        hidden: true,
        hidden_sub_navbar: true,
    },
    {
        path: '/admin/dashboard/broker/client/:id',
        component: () => import('@/views/dashboard/BrokerClientDetails.vue'),
        name: 'admin_dashboard_broker_client_details',
        meta: { title: 'Dashboard Broker Client', icon: 'user' },
        hidden: true,
        hidden_sub_navbar: true,
    },
    {
        path: '/admin/market-watch',
        component: () => import('@/views/market-watch/ListMarketWatch.vue'),
        name: 'admin_market_watch',
        meta: { title: 'Market Watch', icon: 'market-watch' },
        hidden: false,
        hidden_sub_navbar: false,
    },
    {
        path: '/admin/notifications',
        component: () => import('@/views/notifications/ListNotifications.vue'),
        name: 'admin_notification',
        meta: { title: 'Notifications', icon: 'notification' },
        hidden: false,
        hidden_sub_navbar: false,
    },
    
    {
        path: '/admin/action-leagers',
        component: () => import('@/views/action-leagers/ListActionLeagers.vue'),
        name: 'action_leagers',
        meta: { title: 'History', icon: 'action-ledger' },
        hidden: false,
        hidden_sub_navbar: false,
    },
    {
        path: '/admin/active-trade-positions',
        component: () => import('@/views/active-positions/ListActivePositions.vue'),
        name: 'active_positions',
        meta: { title: 'Active Positions', icon: 'verified' },
        hidden: false,
        hidden_sub_navbar: false,
    },
    {
        path: '/admin/active-trade-positions/:id',
        component: () => import('@/views/active-positions/components/ActivePositionClients.vue'),
        name: 'active_positions_clients',
        meta: { title: 'Active Positions Clients', icon: 'user' },
        hidden: true,
        hidden_sub_navbar: true,
    },
    {
        path: '/admin/close-trade-positions',
        component: () => import('@/views/close-positions/ListClosePositions.vue'),
        name: 'close_positions',
        meta: { title: 'Close Positions', icon: 'verified' },
        hidden: false,
        hidden_sub_navbar: false,
    },
    {
        path: '/admin/close-trade-positions/:id',
        component: () => import('@/views/close-positions/components/ClosePositionClients.vue'),
        name: 'close_positions_clients',
        meta: { title: 'Close Positions Clients', icon: 'user' },
        hidden: true,
        hidden_sub_navbar: true,
    },
    {
        path: '/admin/trading-clients',
        component: () => import('@/views/trading-clients/ListTradingClients.vue'),
        name: 'trading_clients',
        meta: { title: 'Trading Clients', icon: 'trading-client' },
        hidden: false,
        hidden_sub_navbar: false,
    },
    {
        path: '/admin/trader-funds',
        component: () => import('@/views/trader-funds/ListTraderFunds.vue'),
        name: 'trader_funds',
        meta: { title: 'Trader Funds', icon: 'funds' },
        hidden: false,
        hidden_sub_navbar: false,
    },
    // {
    //     path: '/admin/payments',
    //     component: () => import('@/views/payment-request/ListPaymentRequest.vue'),
    //     name: 'payments',
    //     meta: { title: 'Payments', icon: 'funds' },
    //     hidden: false,
    //     hidden_sub_navbar: false,
    // },
    // {
    //     path: '/admin/patment-request/:id',
    //     component: () => import('@/views/payment-request/components/AddEdit.vue'),
    //     name: 'payment_request_edit',
    //     meta: { title: 'Payments', icon: 'funds' },
    //     hidden: true,
    //     hidden_sub_navbar: true,
    // },
    // {
    //     path: '/admin/trading-clients-add',
    //     component: () => import('@/views/trading-clients/components/Add.vue'),
    //     name: 'trading_clients_add',
    //     meta: { title: 'Trading Clients Add', icon: 'user' },
    //     hidden: true,
    //     hidden_sub_navbar: true,
    // },
    // {
    //     path: '/admin/trading-clients-edit/:id',
    //     component: () => import('@/views/trading-clients/components/AddEdit.vue'),
    //     name: 'trading_clients_edit',
    //     meta: { title: 'Trading Clients Edit', icon: 'user' },
    //     hidden: true,
    //     hidden_sub_navbar: true,
    // },
    // {
    //     path: '/admin/trading-clients-view/:id',
    //     component: () => import('@/views/trading-clients/components/TradingClientDetails.vue'),
    //     name: 'trading_clients_view',
    //     meta: { title: 'Trading Clients View', icon: 'user' },
    //     hidden: true,
    //     hidden_sub_navbar: true,
    // },

    {
        path: '/admin/trading-clients/trading-clients-add',
        component: () => import('@/views/trading-clients/components/Add.vue'),
        name: 'trading_clients_add',
        meta: { title: 'Trading Clients Add', icon: 'user' },
        hidden: true,
        hidden_sub_navbar: true,
    },

    {
        path: '/admin/trading-clients/trading-clients-edit/:id',
        component: () => import('@/views/trading-clients/components/AddEdit.vue'),
        name: 'trading_clients_edit',
        meta: { title: 'Trading Clients Edit', icon: 'user' },
        hidden: true,
        hidden_sub_navbar: true,
    },

    {
        path: '/admin/trading-clients/trading-clients-view/:id',
        component: () => import('@/views/trading-clients/components/TradingClientDetails.vue'),
        name: 'trading_clients_view',
        meta: { title: 'Trading Clients View', icon: 'user' },
        hidden: true,
        hidden_sub_navbar: true,
    },

    {
        path: '/admin/trades',
        component: () => import('@/views/trades/ListTrades.vue'),
        name: 'trades',
        meta: { title: 'Trades', icon: 'trades' },
        hidden: false,
        hidden_sub_navbar: false,
    },
    {
        path: '/admin/trades/trades-view/:id',
        component: () => import('@/views/trades/components/TradeDetails.vue'),
        name: 'trade-view',
        meta: { title: 'Trade View', icon: 'user' },
        hidden: true,
        hidden_sub_navbar: true,
    },
    {
        path: '/admin/pending-orders',
        component: () => import('@/views/pending-orders/ListPendingOrders.vue'),
        name: 'pending_orders',
        meta: { title: 'Pending Orders', icon: 'orders' },
        hidden: false,
        hidden_sub_navbar: false,
    },
    {
        path: '/admin/pending-orders/:id',
        component: () => import('@/views/pending-orders/components/PendingOrders.vue'),
        name: 'pending_orders',
        meta: { title: 'Pending Orders View', icon: 'orders' },
        hidden: true,
        hidden_sub_navbar: true,
    },

    {
        path: '/admin/trader-funds/:id',
        component: () => import('@/views/trader-funds/components/TraderFunds.vue'),
        name: 'admin_trader_funds_details',
        meta: { title: 'Trader Fund Client', icon: 'user' },
        hidden: true,
        hidden_sub_navbar: true,
    },
    {
        path: '/admin/users',
        component: () => import('@/views/users/ListUsers.vue'),
        name: 'user',
        meta: { title: 'Brokers', icon: 'user' },
        hidden: false,
        hidden_sub_navbar: false,
    },
    // {
    //     path: '/admin/user-funds',
    //     component: () => import('@/views/user-funds/ListUserFunds.vue'),
    //     name: 'user_funds',
    //     meta: { title: 'Broker Funds', icon: 'funds' },
    //     hidden: false,
    //     hidden_sub_navbar: false,
    // },
    {
        path: '/admin/user-funds/:id',
        component: () => import('@/views/user-funds/components/UserFunds.vue'),
        name: 'admin_user_funds_details',
        meta: { title: 'User Fund Client', icon: 'user' },
        hidden: true,
        hidden_sub_navbar: true,
    },
    {
        path: '/admin/users/user-add',
        component: () => import('@/views/users/components/Add.vue'),
        name: 'user-add',
        meta: { title: 'User Add', icon: 'user' },
        hidden: true,
        hidden_sub_navbar: false,
    },
    {
        path: '/admin/users/user-edit/:id',
        component: () => import('@/views/users/components/AddEdit.vue'),
        name: 'user-edit',
        meta: { title: 'User Edit', icon: 'user' },
        hidden: true,
        hidden_sub_navbar: true,
    },
    {
        path: '/admin/users/user-view/:id',
        component: () => import('@/views/users/components/UserDetails.vue'),
        name: 'user-view',
        meta: { title: 'User View', icon: 'user' },
        hidden: true,
        hidden_sub_navbar: true,
    },
    {
        path: '/admin/change-password',
        name: 'change-password',
        meta: { title: 'Change Password', icon: 'user' },
        component: () => import('@/views/users/ChangePassword.vue'),
        hidden_sub_navbar: true,
    },
    {
        path: '/admin/transaction-password',
        name: 'transaction-password',
        meta: { title: 'Transaction Password', icon: 'user' },
        component: () => import('@/views/users/TransactionPassword.vue'),
        hidden_sub_navbar: true,
    },
    {
        path: '/admin/user-profile',
        name: 'user-profile',
        meta: { title: 'User Profile', icon: 'user' },
        component: () => import('@/views/users/UserProfile.vue'),
        hidden_sub_navbar: true,
    },
    {
        path: '/admin/accounts',
        component: () => import('@/views/accounts/ListAccounts.vue'),
        name: 'account',
        meta: { title: 'Accounts', icon: 'calculator' },
        hidden: false,
        hidden_sub_navbar: false,
    },
    // {
    //     path: '/admin/instrument',
    //     component: () => import('@/views/instruments/Instruments.vue'),
    //     name: 'account',
    //     meta: { title: 'Instruments', icon: 'trades' },
    //     hidden: false,
    //     hidden_sub_navbar: false,
    // },
    {
        path: '/admin/settings',
        component: () => import('@/views/settings/Settings.vue'),
        name: 'account',
        meta: { title: 'settings', icon: 'user' },
        hidden: true,
        hidden_sub_navbar: true,
    },
    {
        path: '/admin/settings-update',
        component: () => import('@/views/settings/components/AddEdit.vue'),
        name: 'account',
        meta: { title: 'settings', icon: 'user' },
        hidden: true,
        hidden_sub_navbar: true,
    },
    {
        path: '/admin/notice',
        component: () => import('@/views/notice/NoticeAddEdit.vue'),
        name: 'notice',
        meta: { title: 'Notice', icon: 'user' },
        hidden: false,
        hidden_sub_navbar: false,
    },
    {
        path: '/admin/error-log',
        component: () => import('@/views/error-log/ListErrors.vue'),
        name: 'notice',
        meta: { title: 'Error', icon: 'user' },
        hidden: false,
        hidden_sub_navbar: false,
    },
  
  
]
