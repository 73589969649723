import Cookies from 'js-cookie';
import siteConfig from './site.config';
import {getSubdomain } from '@/utils/auth';

// languages json files
// import enLang from '@/lang/en/index.js'
// import esLang from '@/lang/es/index.js'

const app = {
  state: {
    // language: Cookies.get('language') || 'en',
    // size: Cookies.get('size') || 'medium',
    theme: Cookies.get('theme') || 'dark',
    roleSlug: Cookies.get('roleSlug') || '',
    userId: Cookies.get('userId') || '',
    siteConfig: siteConfig[getSubdomain()],
  },
  getters: {
    // getTrans(state){ // respective language
    //   if(state.language=='en')
    //     return enLang
    //   else if(state.language=='es')
    //     return esLang
    //   else
    //     return enLang // default
    // },
    // getDefaultTrans(){ // default languge
    //   return enLang
    // },
    // getCurrentLanguage(state){
    //   return state.language
    // },
    getTheme(state){
      return state.theme
    },
    getRoleSlug(state){
      return state.roleSlug
    },
    getUserId(state){
      return state.userId
    },
    getSiteConfig(state){
      return state.siteConfig;
    }
  },
  mutations: {
    // SET_LANGUAGE: (state, language) => {
    //   state.language = language
    //   Cookies.set('language', language)
    // },
    // SET_SIZE: (state, size) => {
    //   state.size = size
    //   Cookies.set('size', size)
    // }
     SET_THEME: (state, theme) => {
      state.theme = theme
      Cookies.set('theme', theme)
    },
    SET_USER_LOGIN: (state, roleSlug) => {
      state.roleSlug = roleSlug
      Cookies.set('roleSlug', roleSlug)
    },
    SET_USER_ID: (state, ID) => {
      state.userId = ID
      Cookies.set('userId', ID)
    }
  },
  actions: {
    // setLanguage({ commit }, language) {
    //   commit('SET_LANGUAGE', language)
    // },
    // setSize({ commit }, size) {
    //   commit('SET_SIZE', size)
    // }
    setTheme({ commit }, theme) {
      commit('SET_THEME', theme)
    },
    setRoleSlug({ commit }, roleSlug) {
      commit('SET_USER_LOGIN', roleSlug)
    },
    setUserId({ commit }, userId) {
      commit('SET_USER_ID', userId)
    }
    
  }
}

export default app
