<template>
  <div id="app" :class="{ 'dark-bg-color': getTheme == 'dark' }">
    
    <svg-sprite />
    <router-view></router-view>
  </div>
</template>
<script>
import SvgSprite from "@/components/SvgIcon/sprite.vue";
import { mapGetters } from "vuex";
export default {
  name: "App",
  components: {
    SvgSprite,
  },
  data() {
    return {
      isToggleTheme: false,
    };
  },
   computed: {
    ...mapGetters(["getTheme", "getSiteConfig"]),
  },
  created() {
    document.title = this.getSiteConfig.name;
  },
  methods: {},
};
</script>


<style>
.el-dialog__wrapper {
  z-index: +1000 !important;
}
.v-modal {
  z-index: +999 !important;
}
</style>
