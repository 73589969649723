
export const brokerRoutes = [
    {
        path: '/broker/dashboard',
        component: () => import('@/views/dashboard/Dashboard.vue'),
        name: 'broker_dashboard',
        meta: { config: true, title: 'Dashboard', icon: 'user', module: 'broker'},
        hidden: false,
        hidden_sub_navbar: false,
    },
    {
        path: '/broker/dashboard/broker/:id',
        component: () => import('@/views/dashboard/BrokerDetails.vue'),
        name: 'broker_dashboard_broker_details',
        meta: { config: true, title: 'Market Watch Broker', icon: 'user', module: 'broker' },
        hidden: true,
        hidden_sub_navbar: true,
    },
    {
        path: '/broker/dashboard/broker/client/:id',
        component: () => import('@/views/dashboard/BrokerClientDetails.vue'),
        name: 'broker_dashboard_broker_client_details',
        meta: { config: true, title: 'Dashboard Broker Client', icon: 'user', module: 'broker' },
        hidden: true,
        hidden_sub_navbar: true,
    },
    {
        path: '/broker/market-watch',
        component: () => import('@/views/broker/market-watch/ListMarketWatch.vue'),
        name: 'broker_market_watch',
        meta: { config: true, title: 'Market Watch', icon: 'user', module: 'broker' },
        hidden: false,
        hidden_sub_navbar: false,
    },
    {
        path: '/broker/notifications',
        component: () => import('@/views/notifications/ListNotifications.vue'),
        name: 'broker_notification',
        meta: { config: true, title: 'Notifications', icon: 'user', module: 'broker' },
        hidden: false,
        hidden_sub_navbar: false,
    },
    {
        path: '/broker/action-leagers',
        component: () => import('@/views/action-leagers/ListActionLeagers.vue'),
        name: 'action_leagers',
        meta: { config: true, title: 'History', icon: 'user', module: 'broker' },
        hidden: false,
        hidden_sub_navbar: false,
    },
    {
        path: '/broker/active-trade-positions',
        component: () => import('@/views/active-positions/ListActivePositions.vue'),
        name: 'broker_active_positions',
        meta: { config: true, title: 'Active Positions', icon: 'user', module: 'broker' },
        hidden: false,
        hidden_sub_navbar: false,
    },
    {
        path: '/broker/active-trade-positions/:id',
        component: () => import('@/views/active-positions/components/ActivePositionClients.vue'),
        name: 'active_positions_clients',
        meta: { config: true, title: 'Active Positions Clients', icon: 'user', module: 'broker' },
        hidden: true,
        hidden_sub_navbar: true,
    },
    {
        path: '/broker/close-trade-positions',
        component: () => import('@/views/close-positions/ListClosePositions.vue'),
        name: 'broker_close_positions',
        meta: { config: true, title: 'Close Positions', icon: 'user', module: 'broker' },
        hidden: false,
        hidden_sub_navbar: false,
    },
    {
        path: '/broker/close-trade-positions/:id',
        component: () => import('@/views/close-positions/components/ClosePositionClients.vue'),
        name: 'broker_close_positions_clients',
        meta: { config: true, title: 'Close Positions Clients', icon: 'user', module: 'broker' },
        hidden: true,
        hidden_sub_navbar: true,
    },
    {
        path: '/broker/trades',
        component: () => import('@/views/broker/trades/ListTrades.vue'),
        name: 'broker_trades',
        meta: { config: true, title: 'Trades', icon: 'user', module: 'broker' },
        hidden: false,
        hidden_sub_navbar: false,
    },
    {
        path: '/broker/trades/trades-view/:id',
        component: () => import('@/views/broker/trades/components/TradeDetails.vue'),
        name: 'trade-view',
        meta: { title: 'Trade View', icon: 'user' },
        hidden: true,
        hidden_sub_navbar: true,
    },
    {
        path: '/broker/trader-funds',
        component: () => import('@/views/trader-funds/ListTraderFunds.vue'),
        name: 'trader_funds',
        meta: { title: 'Trader Funds', icon: 'funds' },
        hidden: false,
        hidden_sub_navbar: false,
    },
    {
        path: '/broker/pending-orders',
        component: () => import('@/views/pending-orders/ListPendingOrders.vue'),
        name: 'broker_pending_orders',
        meta: { config: true, title: 'Pending Orders', icon: 'user', module: 'broker' },
        hidden: false,
        hidden_sub_navbar: false,
    },
    {
        path: '/broker/pending-orders/:id',
        component: () => import('@/views/pending-orders/components/PendingOrders.vue'),
        name: 'pending_orders',
        meta: { config: true, title: 'Pending Orders View', icon: 'orders', module: 'broker' },
        hidden: true,
        hidden_sub_navbar: true,
    },
    {
        path: '/broker/trading-clients',
        component: () => import('@/views/trading-clients/ListTradingClients.vue'),
        name: 'broker_trading_clients',
        meta: { config: true, title: 'Trading Clients', icon: 'user', module: 'broker' },
        hidden: false,
        hidden_sub_navbar: false,
    },
    {
        path: '/broker/accounts',
        component: () => import('@/views/broker/accounts/ListAccounts.vue'),
        name: 'broker_account',
        meta: { title: 'Accounts', icon: 'calculator' },
        hidden: false,
        hidden_sub_navbar: false,
    },
    // {
    //     path: '/broker/trading-clients-view/:id',
    //     component: () => import('@/views/trading-clients/components/TradingClientDetails.vue'),
    //     name: 'trading_clients_view',
    //     meta: { title: 'Trading Clients View', icon: 'user' },
    //     hidden: true,
    //     hidden_sub_navbar: true,
    // },
    // {
    //     path: '/broker/trading-clients-add',
    //     component: () => import('@/views/trading-clients/components/Add.vue'),
    //     name: 'trading_clients_add',
    //     meta: { config: true, title: 'Trading Clients Add', icon: 'user', module: 'broker' },
    //     hidden: true,
    //     hidden_sub_navbar: false,
    // },
    // {
    //     path: '/broker/trading-clients-edit/:id',
    //     component: () => import('@/views/trading-clients/components/AddEdit.vue'),
    //     name: 'trading_clients_edit',
    //     meta: { config: true, title: 'Trading Clients Edit', icon: 'user', module: 'broker' },
    //     hidden: true,
    //     hidden_sub_navbar: true,
    // },
    {
        path: '/broker/trading-clients/trading-clients-add',
        component: () => import('@/views/trading-clients/components/Add.vue'),
        name: 'trading_clients_add',
        meta: { title: 'Trading Clients Add', icon: 'user' },
        hidden: true,
        hidden_sub_navbar: true,
    },

    {
        path: '/broker/trading-clients/trading-clients-edit/:id',
        component: () => import('@/views/trading-clients/components/AddEdit.vue'),
        name: 'trading_clients_edit',
        meta: { title: 'Trading Clients Edit', icon: 'user' },
        hidden: true,
        hidden_sub_navbar: true,
    },

    {
        path: '/broker/trading-clients/trading-clients-view/:id',
        component: () => import('@/views/trading-clients/components/TradingClientDetails.vue'),
        name: 'trading_clients_view',
        meta: { title: 'Trading Clients View', icon: 'user' },
        hidden: true,
        hidden_sub_navbar: true,
    },


    // {
    //     path: '/broker/trader-funds',
    //     component: () => import('@/views/trader-funds/ListTraderFunds.vue'),
    //     name: 'broker_trader_funds',
    //     meta: { config: true, title: 'Trader Funds', icon: 'user', module: 'broker' },
    //     hidden: false,
    //     hidden_sub_navbar: false,
    // },
    {
        path: '/broker/trader-funds/:id',
        component: () => import('@/views/trader-funds/components/TraderFunds.vue'),
        name: 'broker_trader_funds_details',
        meta: { config: true, title: 'Trader Fund Client', icon: 'user', module: 'broker' },
        hidden: true,
        hidden_sub_navbar: true,
    },
    // {
    //     path: '/broker/user-funds',
    //     component: () => import('@/views/user-funds/ListUserFunds.vue'),
    //     name: 'user_funds',
    //     meta: { config: true, title: 'User Funds', icon: 'user', module: 'broker' },
    //     hidden: false,
    //     hidden_sub_navbar: false,
    // },
    {
        path: '/broker/user-funds/:id',
        component: () => import('@/views/user-funds/components/UserFunds.vue'),
        name: 'broker_user_funds_details',
        meta: { config: true, title: 'User Fund Client', icon: 'user', module: 'broker' },
        hidden: true,
        hidden_sub_navbar: true,
    },
    // {
    //     path: '/broker/users',
    //     component: () => import('@/views/users/ListUsers.vue'),
    //     name: 'user', module: 'broker',
    //     meta: { config: true, title: 'Users', icon: 'user', module: 'broker' },
    //     hidden: false,
    //     hidden_sub_navbar: false,
    // },
    // {
    //     path: '/broker/user-add',
    //     component: () => import('@/views/users/components/AddEdit.vue'),
    //     name: 'user-add',
    //     meta: { config: true, title: 'User Add', icon: 'user', module: 'broker' },
    //     hidden: true,
    //     hidden_sub_navbar: false,
    // },
    {
        path: '/broker/change-password',
        name: 'change-password',
        meta: { config: true, title: 'Change Password', icon: 'user', module: 'broker' },
        component: () => import('@/views/users/ChangePassword.vue'),
        hidden_sub_navbar: true,
    },
    {
        path: '/broker/user-profile',
        name: 'user-profile',
        meta: { config: true, title: 'User Profile', icon: 'user', module: 'broker' },
        component: () => import('@/views/users/UserProfile.vue'),
        hidden_sub_navbar: true,
    },
    // {
    //     path: '/broker/accounts',
    //     component: () => import('@/views/accounts/ListAccounts.vue'),
    //     name: 'account',
    //     meta: { config: true, title: 'Accounts', icon: 'user', module: 'broker' },
    //     hidden: false,
    //     hidden_sub_navbar: false,
    // },


]
