import Pusher from 'pusher-js/with-encryption'

// let pusherApiKey = ''
// let pusherCluster = ''

let pusherApiKey = 'e7090c7697f53c3cbe57'
let pusherCluster = 'ap2'

const pusher = new Pusher(pusherApiKey, {
  cluster: pusherCluster
})

// Enable pusher logging - don't include this in production
Pusher.logToConsole = true

const channel = pusher.subscribe('my-channel')
pusher.bind('real-time-message', data => {
  console.log('real-time-message',data);
})

export default channel
