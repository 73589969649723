
export const superAdminRoutes = [
    {
        path: '/super-admin/dashboard',
        component: () => import('@/views/dashboard/SuperAdminDashboard.vue'),
        name: 'super-admin dashboard',
        meta: { title: 'Dashboard', icon: 'user' },
        hidden: false,
        hidden_sub_navbar: false,
    },
    {
        path: '/super-admin/change-password',
        name: 'change-password',
        meta: { title: 'Change Password', icon: 'user' },
        component: () => import('@/views/users/ChangePassword.vue'),
        hidden_sub_navbar: true,
    },
    {
        path: '/super-admin/dashboard/admin-details/:id',
        component: () => import('@/views/dashboard/super-admin/AdminDetails.vue'),
        name: 'admin_dashboard_broker_details',
        meta: { title: 'Dashboard Broker', icon: 'user' },
        hidden: true,
        hidden_sub_navbar: true,
    },
    {
        path: '/super-admin/dashboard/broker/:id',
        component: () => import('@/views/dashboard/BrokerDetails.vue'),
        name: 'admin_dashboard_broker_details',
        meta: { title: 'Dashboard Broker', icon: 'user' },
        hidden: true,
        hidden_sub_navbar: true,
    },
    {
        path: '/super-admin/dashboard/admin/broker-details/:master/:id',
        component: () => import('@/views/dashboard/super-admin/BrokerDetails.vue'),
        name: 'admin_dashboard_broker_client_details',
        meta: { title: 'Dashboard Broker Client', icon: 'user' },
        hidden: true,
        hidden_sub_navbar: true,
    },
    {
        path: '/super-admin/dashboard/admin/broker/client-details/:id',
        component: () => import('@/views/dashboard/super-admin/ClientDetails.vue'),
        name: 'admin_dashboard_broker_client_details',
        meta: { title: 'Dashboard Broker Client', icon: 'user' },
        hidden: true,
        hidden_sub_navbar: true,
    },
    {
        path: '/super-admin/market-watch',
        component: () => import('@/views/super-admin/market-watch/ListMarketWatch.vue'),
        name: 'super_admin_market_watch',
        meta: { title: 'Market Watch', icon: 'market-watch' },
        hidden: false,
        hidden_sub_navbar: false,
    },
    {
        path: '/super-admin/admins',
        component: () => import('@/views/super-admin/users/ListUsers.vue'),
        name: 'admins',
        meta: { title: 'Masters', icon: 'user' },
        hidden: false,
        hidden_sub_navbar: false,
    },
    {
        path: '/super-admin/admins/user-add',
        component: () => import('@/views/super-admin/users/components/Add.vue'),
        name: 'user-add',
        meta: { title: 'User Add', icon: 'user' },
        hidden: true,
        hidden_sub_navbar: false,
    },
    {
        path: '/super-admin/admins/user-edit/:id',
        component: () => import('@/views/super-admin/users/components/AddEdit.vue'),
        name: 'user-edit',
        meta: { title: 'User Edit', icon: 'user' },
        hidden: true,
        hidden_sub_navbar: true,
    },
    {
        path: '/super-admin/admins/user-view/:id',
        component: () => import('@/views/super-admin/users/components/UserDetails.vue'),
        name: 'user-view',
        meta: { title: 'User View', icon: 'user' },
        hidden: true,
        hidden_sub_navbar: true,
    },
    {
        path: '/super-admin/notifications',
        component: () => import('@/views/notifications/ListNotifications.vue'),
        name: 'notification',
        meta: { title: 'Notifications', icon: 'notification' },
        hidden: false,
        hidden_sub_navbar: false,
    },
    {
        path: '/super-admin/action-leagers',
        component: () => import('@/views/action-leagers/ListActionLeagers.vue'),
        name: 'action_leagers',
        meta: { title: 'History', icon: 'action-ledger' },
        hidden: false,
        hidden_sub_navbar: false,
    },
    // {
    //     path: '/super-admin/active-trade-positions',
    //     component: () => import('@/views/active-positions/ListActivePositions.vue'),
    //     name: 'active_positions',
    //     meta: { title: 'Active Positions', icon: 'verified' },
    //     hidden: false,
    //     hidden_sub_navbar: false,
    // },
    {
        path: '/super-admin/active-trade-positions/:id',
        component: () => import('@/views/active-positions/components/ActivePositionClients.vue'),
        name: 'active_positions_clients',
        meta: { title: 'Active Positions Clients', icon: 'user' },
        hidden: true,
        hidden_sub_navbar: true,
    },
    // {
    //     path: '/super-admin/close-trade-positions',
    //     component: () => import('@/views/close-positions/ListClosePositions.vue'),
    //     name: 'close_positions',
    //     meta: { title: 'Close Positions', icon: 'verified' },
    //     hidden: false,
    //     hidden_sub_navbar: false,
    // },
    {
        path: '/super-admin/close-trade-positions/:id',
        component: () => import('@/views/close-positions/components/ClosePositionClients.vue'),
        name: 'close_positions_clients',
        meta: { title: 'Close Positions Clients', icon: 'user' },
        hidden: true,
        hidden_sub_navbar: true,
    },
    {
        path: '/super-admin/trading-clients',
        component: () => import('@/views/super-admin/trading-clients/ListTradingClients.vue'),
        name: 'trading_clients',
        meta: { title: 'Trading Clients', icon: 'trading-client' },
        hidden: false,
        hidden_sub_navbar: false,
    },
    {
        path: '/super-admin/trader-funds',
        component: () => import('@/views/trader-funds/ListTraderFunds.vue'),
        name: 'trader_funds',
        meta: { title: 'Trader Funds', icon: 'funds' },
        hidden: false,
        hidden_sub_navbar: false,
    },
    // {
    //     path: '/super-admin/trading-clients-add',
    //     component: () => import('@/views/trading-clients/components/AddEdit.vue'),
    //     name: 'trading_clients_add',
    //     meta: { title: 'Trading Clients Add', icon: 'user' },
    //     hidden: true,
    //     hidden_sub_navbar: true,
    // },
    // {
    //     path: '/super-admin/trading-clients-edit/:id',
    //     component: () => import('@/views/trading-clients/components/AddEdit.vue'),
    //     name: 'trading_clients_edit',
    //     meta: { title: 'Trading Clients Edit', icon: 'user' },
    //     hidden: true,
    //     hidden_sub_navbar: true,
    // },
    // {
    //     path: '/super-admin/trading-clients-view/:id',
    //     component: () => import('@/views/trading-clients/components/TradingClientDetails.vue'),
    //     name: 'trading_clients_view',
    //     meta: { title: 'Trading Clients View', icon: 'user' },
    //     hidden: true,
    //     hidden_sub_navbar: true,
    // },




    {
        path: '/super-admin/trading-clients/trading-clients-add',
        component: () => import('@/views/trading-clients/components/Add.vue'),
        name: 'trading_clients_add',
        meta: { title: 'Trading Clients Add', icon: 'user' },
        hidden: true,
        hidden_sub_navbar: true,
    },

    {
        path: '/super-admin/trading-clients/trading-clients-edit/:id',
        component: () => import('@/views/trading-clients/components/AddEdit.vue'),
        name: 'trading_clients_edit',
        meta: { title: 'Trading Clients Edit', icon: 'user' },
        hidden: true,
        hidden_sub_navbar: true,
    },

    {
        path: '/super-admin/trading-clients/trading-clients-view/:id',
        component: () => import('@/views/trading-clients/components/TradingClientDetails.vue'),
        name: 'trading_clients_view',
        meta: { title: 'Trading Clients View', icon: 'user' },
        hidden: true,
        hidden_sub_navbar: true,
    },
    {
        path: '/super-admin/trades',
        component: () => import('@/views/trades/ListTrades.vue'),
        name: 'trades',
        meta: { title: 'Trades', icon: 'trades' },
        hidden: false,
        hidden_sub_navbar: false,
    },
    {
        path: '/super-admin/chamka',
        component: () => import('@/views/super-admin/chamka/ListTrades.vue'),
        name: 'trades',
        meta: { title: 'Chamka Trades', icon: 'trades' },
        hidden: false,
        hidden_sub_navbar: false,
    },
    {
        path: '/super-admin/trades/trades-view/:id',
        component: () => import('@/views/trades/components/TradeDetails.vue'),
        name: 'trade-view',
        meta: { title: 'Trade View', icon: 'user' },
        hidden: true,
        hidden_sub_navbar: true,
    },
    // {
    //     path: '/super-admin/pending-orders',
    //     component: () => import('@/views/pending-orders/ListPendingOrders.vue'),
    //     name: 'pending_orders',
    //     meta: { title: 'Pending Orders', icon: 'orders' },
    //     hidden: false,
    //     hidden_sub_navbar: false,
    // },
    {
        path: '/super-admin/pending-orders/:id',
        component: () => import('@/views/pending-orders/components/PendingOrders.vue'),
        name: 'pending_orders',
        meta: { title: 'Pending Orders View', icon: 'orders' },
        hidden: true,
        hidden_sub_navbar: true,
    },

    {
        path: '/super-admin/trader-funds/:id',
        component: () => import('@/views/trader-funds/components/TraderFunds.vue'),
        name: 'admin_trader_funds_details',
        meta: { title: 'Trader Fund Client', icon: 'user' },
        hidden: true,
        hidden_sub_navbar: true,
    },

    {
        path: '/super-admin/user-funds/:id',
        component: () => import('@/views/user-funds/components/UserFunds.vue'),
        name: 'admin_user_funds_details',
        meta: { title: 'User Fund Client', icon: 'user' },
        hidden: true,
        hidden_sub_navbar: true,
    },
    {
        path: '/super-admin/users',
        component: () => import('@/views/users/ListUsers.vue'),
        name: 'user',
        meta: { title: 'Brokers', icon: 'user' },
        hidden: false,
        hidden_sub_navbar: false,
    },
    {
        path: '/super-admin/users/broker-add',
        component: () => import('@/views/users/components/Add.vue'),
        name: 'broker-add',
        meta: { title: 'User Add', icon: 'user' },
        hidden: true,
        hidden_sub_navbar: false,
    },
    {
        path: '/super-admin/users/broker-edit/:id',
        component: () => import('@/views/users/components/AddEdit.vue'),
        name: 'broker-edit',
        meta: { title: 'User Edit', icon: 'user' },
        hidden: true,
        hidden_sub_navbar: true,
    },
    {
        path: '/super-admin/users/broker-view/:id',
        component: () => import('@/views/users/components/UserDetails.vue'),
        name: 'broker-view',
        meta: { title: 'User View', icon: 'user' },
        hidden: true,
        hidden_sub_navbar: true,
    },
    // {
    //     path: '/super-admin/user-funds',
    //     component: () => import('@/views/user-funds/ListUserFunds.vue'),
    //     name: 'user_funds',
    //     meta: { title: 'Broker Funds', icon: 'funds' },
    //     hidden: false,
    //     hidden_sub_navbar: false,
    // },
    {
        path: '/super-admin/registration-requests',
        component: () => import('@/views/super-admin/user-registration/ListUserRegistrations.vue'),
        name: 'user-registration-requests',
        meta: { title: 'Registration requests', icon: 'user' },
        hidden: false,
        hidden_sub_navbar: false,
    },
    {
        path: '/super-admin/user-complaint',
        component: () => import('@/views/super-admin/user-complaint/ListUserComplaint.vue'),
        name: 'user-complaint',
        meta: { title: 'User complaint', icon: 'user' },
        hidden: false,
        hidden_sub_navbar: false,
    },
    {
        path: '/super-admin/settings',
        component: () => import('@/views/super-admin/settings/Settings.vue'),
        name: 'account',
        meta: { title: 'settings', icon: 'user' },
        hidden: true,
        hidden_sub_navbar: true,
    },
    {
        path: '/super-admin/settings-update',
        component: () => import('@/views/super-admin/settings/components/AddEdit.vue'),
        name: 'account',
        meta: { title: 'settings', icon: 'user' },
        hidden: true,
        hidden_sub_navbar: true,
    },

    {
        path: '/super-admin/accounts',
        component: () => import('@/views/super-admin/accounts/ListAccounts.vue'),
        name: 'account',
        meta: { title: 'Accounts', icon: 'calculator' },
        hidden: false,
        hidden_sub_navbar: false,
    },

    {
        path: '/super-admin/notice',
        component: () => import('@/views/super-admin/notice/NoticeAddEdit.vue'),
        name: 'notice',
        meta: { title: 'Notice', icon: 'user' },
        hidden: false,
        hidden_sub_navbar: false,
    },
    {
        path: '/super-admin/marque',
        component: () => import('@/views/super-admin/marque/MarqueAddEdit.vue'),
        name: 'marque',
        meta: { title: 'Marque', icon: 'notification' },
        hidden: false,
        hidden_sub_navbar: false,
    },
    {
        path: '/super-admin/limit-point-gapping',
        component: () => import('@/views/super-admin/LimitPointGapping/LimitPointGappingAddEdit.vue'),
        name: 'LimitPointGapping',
        meta: { title: 'LimitPointGapping', icon: 'notification' },
        hidden: false,
        hidden_sub_navbar: false,
    },

]
