import axios from 'axios'
// import { MessageBox } from 'element-ui'
import store from '@/store'
// import getRoleSlug from '@/store/app.js'
import app from '@/store/app.js'
import { getToken, getRoleSlug } from '@/utils/auth'
// import { getToken, getLocationToken } from '@/utils/auth'


// device infos : for request headers
import { isMobileOnly, isTablet, isBrowser, isSmartTV, isWearable, isConsole, isAndroid, isWinPhone, isIOS, osName, osVersion, browserName, fullBrowserVersion } from 'mobile-device-detect'
// import { isEmpty } from 'underscore'
let deviceInfo = getDeviceInfo(isMobileOnly, isTablet, isBrowser, isSmartTV, isWearable, isConsole, isAndroid, isWinPhone, isIOS, osName, osVersion, browserName, fullBrowserVersion)
let deviceInfoHeaders = {};
deviceInfoHeaders['device_type'] = deviceInfo.device_type;
deviceInfoHeaders['device_os_name'] = deviceInfo.device_os_name;
deviceInfoHeaders['device_os_version'] = deviceInfo.device_os_version;
//deviceInfoHeaders['device_browser_name'] = deviceInfo.device_browser_name;
deviceInfoHeaders['device_browser_version'] = deviceInfo.device_browser_version;

// let baseurl = app.state.siteConfig.api + 'api/'
// if(!isEmpty(getRoleSlug())){
//   baseurl = baseurl + getRoleSlug() +'/';
// }
// console.log("role="+getRoleSlug.state.roleSlug)
// create an axios instance
const service = axios.create({
  // baseURL: process.env.VUE_APP_BASE_API, // api of base_url
  //baseURL: baseurl,
  baseURL: app.state.siteConfig.api + 'api/' + getRoleSlug() +'/',
  //baseURL: 'http://localhost:8082/api/'+ getRoleSlug() +'/',
  timeout: 1000000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    //config.baseURL = baseurl;
    config.baseURL = app.state.siteConfig.api + 'api/' + getRoleSlug() +'/';
    //config.baseURL = 'http://localhost:8082/api/'+ getRoleSlug() +'/';
    // Do something before request is sent
    if (store.getters.token) {
      config.headers['Authorization'] = 'Bearer ' + getToken()
      // config.headers['Access-Control-Allow-Origin'] = '*'
      config.headers['Access-Control-Allow-Headers'] = 'Authorization'
    }

    // appending device information in headers
    config.headers = { ...config.headers, ...deviceInfoHeaders }

    // for location tokens
    // if (store.getters.location_token) {
    //   config.params = { ...config.params, location_token: getLocationToken() }
    // }

    return config
  },
  error => {
    // Do something with request error
    console.log(error) // for debug
    Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => response,
  
  error => {
    

    if(error.response.status == 403){
      store.dispatch('FedLogOut').then(() => {
      if(location.href.includes('/#/super-admin')) {
          location.href = location.origin + '/#/super-admin';
      } else if(location.href.includes('/#/admin')) {
        location.href = location.origin + '/#/admin';
      }else if(location.href.includes('/#/broker')) {
        location.href = location.origin + '/#/broker';
      }
    })
    }

    
    
    
    
    // for debug
    // if (error.message === 'Request failed with status code 401') {
    //   MessageBox.confirm('You have been logged out due to inactivity. Please click OK and log in again.', {
    //     confirmButtonText: 'OK',
    //     cancelButtonText: 'Cancel',
    //     type: 'warning',
    //     center: true,
    //     showClose: false,
    //     showCancelButton: false
    //   }).then(() => {
    //     store.dispatch('FedLogOut').then(() => {
    //       location.reload() // In order to re-instantiate the vue-router object to avoid bugs
    //     })
    //   })
    // }
    return Promise.reject(error)
  }
)


function getDeviceInfo(isMobileOnly, isTablet, isBrowser, isSmartTV, isWearable, isConsole, isAndroid, isWinPhone, isIOS, osName, osVersion, browserName, fullBrowserVersion) {
  let deviceType = ''
  if (isMobileOnly) {
    deviceType = 'mobile'
  } else if (isTablet) {
    deviceType = 'tablet'
  } else if (isMobileOnly) {
    deviceType = 'mobile'
  } else if (isBrowser) {
    deviceType = 'computer' // 'browser'
  } else if (isSmartTV) {
    deviceType = 'smarttv'
  } else if (isWearable) {
    deviceType = 'wearable'
  } else if (isConsole) {
    deviceType = 'console'
  }

  let deviceOSName = ''
  if (isAndroid) {
    deviceOSName = 'Android'
  } else if (isWinPhone) {
    deviceOSName = 'Windows Phone'
  } else if (isIOS) {
    deviceOSName = 'iOS'
  } else if (osName) {
    deviceOSName = osName
  }

  return {
    device_type: deviceType,
    device_os_name: deviceOSName,
    device_os_version: osVersion,
//    device_browser_name: browserName,
    device_browser_version: fullBrowserVersion
  }
}

export default service
