<template>
  <div>
    <el-row style="margin-bottom: 0px !important">
      <div class="">
        <div class="wrapper-header">
          <div class="logo-magic-wrapper">
            <h3 @click="dashboardRoter">
              <span><img
                :src="logo"
              /> </span>{{ getSiteConfig.name }}</h3>
          </div>
          <div>
            <span @click="sidebarToggel" class="hambergerNav2">
              <i class="el-icon-d-arrow-left"></i>
            </span>

            <span @click="navbarToggel" class="hambergerNav">
              <i class="el-icon-d-arrow-left"></i>
            </span>
          </div>
        </div>
        <div class="nav-custom">
          <div class="nav-menus">
            <h1>{{ userInfo.username }}</h1>
            <p>
              <b> {{ userInfo.name }}</b>
            </p>
            <div class="avatar-image">
              <img
                src="@/assets/avatar.jpg"
              />
            </div>
            <!-- {{getRoleSlug}} -->
          </div>
          <el-menu
            default-active="2"
            class="el-menu-vertical-demo"
            background-color="transparent !important"
            text-color="#fff"
            active-text-color="#ffd04b"
          >
            <el-menu-item
              index="4"
              v-for="item in getActiveConstantRoutes(constantRoutes, true)"
              :key="item.name"
            >
              <span
                v-for="item2 in getActiveConstantRoutes(item.children)"
                :key="item2.id"
              >
                <span
                  @click="navbarToggel"
                  v-for="item3 in getActiveConstantRoutes(item2.children)" :key="item3.id">
                  <router-link :to="item3.path" v-if="item.name==getRoleSlug">
                    <svg-icon :icon-name="item3.meta.icon" />
                    <span>{{ item3.meta.title }}</span>
                  </router-link>
                </span>
              </span>
            </el-menu-item>
          </el-menu>
        </div>
      </div>
    </el-row>
  </div>
</template>








<script>
import { _ } from "vue-underscore";
import { constantRoutes as importConstantRoutes } from "@/router/router.js";
import { mapGetters } from "vuex";

// Pusher
import channel from "@/pusher.js";

// event bus
import eventBus from "@/eventBus";

// import VueSlickCarousel from "vue-slick-carousel";
// import "vue-slick-carousel/dist/vue-slick-carousel.css";
// // optional style for arrows & dots
// import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  name: "Navbar",
  components: {
    // constantRoutes
    // VueSlickCarousel,
  },
  data() {
    return {
      constantRoutes: [],
      settings: {
        centerMode: true,
        centerPadding: "20px",
        focusOnSelect: false,
        infinite: true,
        slidesToShow: 7,
        speed: 500,
        responsive: [
          {
            breakpoint: 576,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
      logo: null,
    };
  },
  computed: {
    ...mapGetters(["userInfo", "getTheme", "getRoleSlug","permissions","getSiteConfig"]),
  },
  created() {
    this.constantRoutes = importConstantRoutes;
    // console.log(this.constantRoutes);
      // /////////////////////////////
      // PUSHER FOR RECEIVING EVENTS
      // /////////////////////////////
     channel.bind("real-time-message", function (data) {
        console.log("real-time-message",data);
        eventBus.$emit("real-time-message", true);
      });

  },

  mounted() {
    this.loadDynamicImgs();
  },

  methods: {
    getActiveConstantRoutes(constantRoutes, s) {
      let activeConstantRoutes = [];
      _.each(constantRoutes, (item) => {
        if (!item.hidden && !item.hidden_sub_navbar) {
          if(this.getRoleSlug == item.name || !s ) {
             if(item.name == 'broker_notification' || item.name == 'admin_notification'){
              if(this.userInfo.allow_to_notification_view){
                activeConstantRoutes.push(item);
              }
             }else if(item.name == 'trader_funds' && this.getRoleSlug == "broker"){
              if(this.userInfo.allow_broker_fund_action){
                activeConstantRoutes.push(item);
              }
             }else if(item.name == 'action_leagers' && this.getRoleSlug == "broker"){
              if(this.userInfo.allow_to_history_view){
                activeConstantRoutes.push(item);
              }
             }else{
              activeConstantRoutes.push(item);
             }
          }
        }
      });
      return activeConstantRoutes;
    },
    getActivePermissionRoutes(constantRoutes) {
      // console.log("constantRoutesconstantRoutes",constantRoutes)
      var activeConstantRoutes = [];
      _.each(constantRoutes, (item) => {
        // console.log('item',item)
        if (!item.hidden && !item.hidden_sub_navbar) {
          // console.log('item',item.meta.module)
          if (item.meta.module == 'broker') {
            // console.log('item.name',item.name)
            if(item.name == "broker_trading_clients") {
              // console.log('item.meta.config',item.meta.config)
              // console.log('this.permissions',this.permissions)
              // console.log('this.permissions.config_create_client',this.permissions.config_create_client)
              if(item.meta.config == this.permissions.config_create_client){
                activeConstantRoutes.push(item);
              }
            }
            else if(item.name == "broker_trader_funds") {
              // if(item.meta.config == this.permissions ? this.permissions.config_trader_fund : null){
              if(item.meta.config == this.permissions.config_trader_fund){
                activeConstantRoutes.push(item);
              }
            }
            else{
              activeConstantRoutes.push(item);
            }
          }else {
            activeConstantRoutes.push(item);
          }
        }
      });
      // console.log("activeConstantRoutes",activeConstantRoutes)
      return activeConstantRoutes;
    },
    isMenuActive(name) {
      const p = this.$route.path;
      return p.includes(name);
    },
    closeNav() {
      // console.log("hit");
      this.navbarToggel();
    },
    navbarToggel() {
      // console.log("hit");
      this.$emit("getChildNavbarShowData", "isNavbarShow");
    },
    sidebarToggel() {
      // console.log("hit");
      this.$emit("getChildNavbarCollapsedData", "isNavbarCollapsed");
    },

    dashboardRoter() {
      if(location.href.includes('/#/super-admin')) {
          location.href = location.origin + '/#/super-admin/dashboard';
      } else if(location.href.includes('/#/admin')) {
         location.href = location.origin + '/#/admin/dashboard';
      }else if(location.href.includes('/#/broker')) {
         location.href = location.origin + '/#/broker/dashboard';
      }
    },
    loadDynamicImgs() {
      import(`@/assets/img_${this.getSiteConfig.logo}`).then((module) => this.logo = module.default);
    }

  },
};
</script>
