
const siteConfig = {
    
    localhost: {
        api: 'http://localhost:1095/',
        logo: 'tcssolutions/Profit-Logo.png',
        loginBg: 'tcssolutions/login-bg.jpg',
        name: 'Profit',
        socketUrl: 'http://localhost:1095',
        theme: 'black.scss',
        is_crypto: true
    },

    "profit-guru": {
        api: 'https://webapi.profit-guru.com/',
        logo: 'profit-guru/Profit-Logo.png',
        loginBg: 'profit-guru/login-bg.jpg',
        name: 'Profit',
        socketUrl: 'https://webapi.profit-guru.com',
        theme: 'black.scss',
        is_crypto: false
    },
};

export default siteConfig;