<template>
  <div>
    <div class="nav-menu">
      <div class="navbar navbar-shadow">
        <div class="container-fluid">
          <div class="navbar-header">
            <span>
              <span @click="navbarToggel" class="hambergerNav">
                <i class="el-icon-d-arrow-right"></i>
              </span>
            </span>
          </div>
          <div class="nav-header">
            <ul class="special-icons">
              <li class="location-name-wrapper">
                <span v-show="isShow" @click="sidebarToggel">
                  <i style="color:#fff;" class="el-icon-d-arrow-right"></i>
                </span>
              </li>
              <li class="location-name-wrapper">
                <el-radio-group v-model="radio" @change="setTheme" size="mini">
                  <el-radio-button :label="'light'">
                    <i class="el-icon-sunrise"></i>
                  </el-radio-button>
                  <el-radio-button :label="'dark'">
                    <i class="el-icon-moon"></i
                  ></el-radio-button>
                </el-radio-group>
              </li>
            </ul>
            <ul class="rightside-icons">
              <li>
                <ul class="left">
                  <li class="hamberger-icon">
                    <a @click="handleShowHideMenus()"
                      ><i class="el-icon-s-unfold"></i
                    ></a>
                  </li>
                  <!-- roleValue {{roleValue}}
                  userInfo {{ userInfo }} -->

                  <!-- <el-button  @click="checkBrokerActiveStatus">click</el-button> -->
                  <li v-if="getRoleSlug == 'admin'">
                    <small><b> RENEWAL: {{getFormatedDate(userInfo.renewaldate)}} </b></small>
                  </li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <li v-if="getRoleSlug == 'admin'">
                    <small><b> CLIENTS: {{userInfo.customerCount}}/{{userInfo.allowedclient}} </b></small>
                  </li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <li v-if="getRoleSlug == 'admin'">
                    <small><b> USERS: {{userInfo.brokerCount}}/{{userInfo.allowedbroker}} </b></small>
                  </li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <!-- settings only for admin  -->
                 
                  
                  
                  <li v-if="getRoleSlug == 'admin'">
                    <router-link to="/admin/settings"
                      ><i class="el-icon-s-tools"></i
                    ></router-link>
                    
                  </li>
                  <li v-if="getRoleSlug == 'superadmin'">
                    <router-link to="/super-admin/settings"
                      ><i style="color:#fff;" class="el-icon-s-tools"></i
                    ></router-link>
                  </li>
                  <!-- <li v-if="getRoleSlug == 'admin'" style="margin-right: 15px;">
                    <el-badge :value="userInfo.pending_payment_count" class="item bell-badge">
                      <el-tooltip
                        class="item"
                        effect="dark"
                        content="Payments"
                        placement="top-start"
                      >
                        <router-link :to="'/'+ roleSlug + '/payments'">
                          <i style="color:#fff;" class="el-icon-wallet"></i>
                        </router-link>
                      </el-tooltip>
                    </el-badge>
                  </li> -->
                  <li>
                    <el-badge :value="userInfo.notificationCount" class="item bell-badge">
                      <el-tooltip
                        class="item"
                        effect="dark"
                        content="Notifications"
                        placement="top-start"
                      >
                        <router-link :to="'/'+ roleSlug + '/notifications'">
                          <i style="color:#fff;" class="el-icon-bell"></i>
                        </router-link>
                      </el-tooltip>
                    </el-badge>
                  </li>

                  

                </ul>
              </li>

              <!-- <li v-if="getRoleSlug == 'admin'">
                <el-popover
                    placement="bottom-end"
                    width="450"
                    popper-class="popper_class"
                    trigger="click">

                    <el-table class="custom-box-shadow" :row-class-name="customRowClassName" :data="historyInfo" :show-header="false" >
                      <el-table-column width="295" property="message" ></el-table-column>
                      <el-table-column width="100" property="created_at"  ></el-table-column>
                    </el-table>
                    <el-button slot="reference"><i style="color:#fff;" class="el-icon-wallet" @click="handleHistoryData()"></i></el-button>
                  </el-popover>
              </li> -->
             
              <li v-if="userInfo.history_notification">
                <el-dropdown class="user-container" trigger="click">
                  
                  <i style="color:#fff;" class="el-icon-wallet" @click="handleHistoryData()"></i>
                  <el-dropdown-menu class="profile-dropdown popper_class" slot="dropdown">
                    <el-dropdown-item class="history-notification" v-for="item in historyInfo" :key="item.message">
                      <div class="notification-dropdown">
                        <p class="message">{{ item.message }}</p>
                        <p class="date">{{ item.created_at }}</p>
                      </div>
                     
                    </el-dropdown-item>
                   
                  </el-dropdown-menu>
                </el-dropdown>
              </li>
              <li>
                <el-dropdown class="user-container" trigger="click">
                  <div style="color:#fff;">
                    {{ userInfo.username }}
                    <div class="role">{{ getRoleSlug }}</div>
                  </div>
                  <div>
                    <el-avatar
                      size="large"
                      src="@/assets/avatar.jpg"
                    >
                    </el-avatar>
                    <span class="avatar-status">&nbsp;</span>
                  </div>

                  <el-dropdown-menu class="profile-dropdown " slot="dropdown">
                    <!-- <el-dropdown-item>
                      <p @click="handleUserProfile()">Leger-Balance:- 00</p>
                    </el-dropdown-item> -->
                    <el-dropdown-item >
                      <p @click="handleUserChangePassword()">Change Password</p>
                    </el-dropdown-item>
                    <el-dropdown-item  v-if="getRoleSlug == 'admin'">
                      <p @click="handleUserTransactionPassword()">Transaction Password</p>
                    </el-dropdown-item>
                    <el-dropdown-item divided>
                      <span @click="handleLogout">
                        <svg-icon icon-name="shutdown" />&nbsp;&nbsp;Logout
                      </span>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>






<script>
import { mapActions, mapGetters } from "vuex";
import getRoleSlug from "@/store/app.js";
//import { fetchList } from "@/api/action-leager";
export default {
  name: "Navbar",
  components: {},
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(["userInfo", "getTheme", "getRoleSlug","getUserId","historyInfo"]),
  },
  data() {
    return {
      roleSlug:"",
      radio: 'dark',
      key:"notification",
      historyData :[],
      // roleValue: getRoleSlug.state.roleSlug
    };
  },
  
  sockets: {
    connect: function () {
      console.log('connected live sockets 5 ......')
    },

    
    notification: function (data) {
      if(this.userInfo.id == data.id){
          this.userInfo.notificationCount = data.count
          // let audio = new Audio('/assets/notification.mp3'); // path to file
          // audio.play();
          this.playSound()
      }
    },
    paymentNotification: function (data) {
      if(this.userInfo.id == data.id){
          this.userInfo.pending_payment_count = data.count
          this.playSound()
      }
    },

    newTrade: function (data) {
      console.log("come data with s", data, data.item)
      data = JSON.parse(data)
      if(this.userInfo.id == data.id){
          this.historyInfo.unshift(data.item);
        console.log("user info", this.userInfo)
          if(this.userInfo.sound_play_in_trade){
            this.playSound()
          }  
      }
    },
    
},
  
  created() {
    this.radio = this.getTheme;
    this.pageLoadedORRefreshed(); // page loaded or refereshed : fired in both cases
    this.getHistory();
    // this.checkBrokerActiveStatus();
    if(getRoleSlug.state.roleSlug == "superadmin"){
      this.roleSlug = "super-admin"
    }else{
      this.roleSlug = getRoleSlug.state.roleSlug
    }

    console.log("iser " , this.userInfo)
    
  },

  // watch: {
  //   roleValue() {
  //     // this.checkBrokerActiveStatus();
  //     alert('hello')
  //   },
  //   deep: true,
  //   immediate: true,
  // },

  methods: {

    customRowClassName() {
      return 'custom-row-padding';
    },
    ...mapActions(["pageLoadedORRefreshed", "LogOut", 'getHistory']),

    sidebarToggel() {
      this.$emit("getChildNavbarCollapsedData", "isNavbarCollapsed");
    },
    navbarToggel() {
      console.log("hit");
      this.$emit("getChildNavbarShowData", "isNavbarShow");
    },
    ...mapActions(["setTheme"]),

    handleUserChangePassword() {
      if(getRoleSlug.state.roleSlug == 'superadmin') {
        return this.$router.push(
        "/super-admin/change-password"
      );
      }else{
        return this.$router.push(
        "/" + getRoleSlug.state.roleSlug + "/change-password"
      );
      }
     
    },
    handleUserTransactionPassword() {
        return this.$router.push(
        "/" + getRoleSlug.state.roleSlug + "/transaction-password"
        )
    },
    handleHistoryData() {
      // console.log("sdsdghhggggg ")
       this.getHistory();
      // fetchList(this.listQuery).then((response) => {
      //   this.historyData = response.data.data.data;
      //   //this.tablePagination = response.data.data.meta;
      //   // this.listDataTemp = this.listData.data.item = response.data.data.data;
      //   //this.loading = false;
      // });

     
        console.log("this.historyInfo", this.historyInfo)
     
    },
    checkBrokerActiveStatus() {
      // console.log('inn')
      // console.log('userInfo.username',this.userInfo)
      // console.log('userInfo.config_account_status',this.userInfo.config_account_status)
      // console.log('userInfo.getRoleSlug',this.getRoleSlug)
      if(this.getRoleSlug == 'broker' && this.userInfo.config_account_status == true) {
      
        this.handleLogout();
        this.flashError("Account Status Disabled");
      }
    },
    handleLogout() {
      // this.loading = false;
      //     this.$router.push({
      //       path: "/"+ getRoleSlug.state.roleSlug +'/'
      //     });

      this.$store
        .dispatch("LogOut")
        .then(() => {
          // this.$router.push("/");
          console.log(getRoleSlug.state.roleSlug);
          if(getRoleSlug.state.roleSlug == 'superadmin') {
              this.$router.push({
                path: "/super-admin/",
              });
          } else {
              this.$router.push({
                path: "/" + getRoleSlug.state.roleSlug + "/",
              });
          }
          console.log("Logout",getRoleSlug.state.roleSlug);
          // location.reload();
        })
        .catch(() => {
          console.log("Logout error");
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.sidebar-collapsed {
  width: 0px;
}
</style>
