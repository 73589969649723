<template>
  <div>
    <el-row v-if="isNavbarCollapsed" style="margin-bottom: 0px !important">
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <Header
          @getChildNavbarCollapsedData="handleChildNavbarCollapsedData($event)"
          @getChildNavbarShowData="handleChildNavbarShowData($event)"
          @isShow="isShow($event)"
          :isShow="isShow"
        />
        <div class="topbar-fixed">
          <AppMain />
        </div>
      </el-col>
    </el-row>
    <el-row v-else style="margin-bottom: 0px !important">
      <el-col
        :xs="24"
        :sm="24"
        :md="4"
        :lg="4"
        :xl="4"
        :class="{ 'navbar-wrapper': isNavbarShow }"
        style="padding: 0px"
      >
        <Navbar
          @getChildNavbarShowData="handleChildNavbarShowData($event)"
          @getChildNavbarCollapsedData="handleChildNavbarCollapsedData($event)"
        />
      </el-col>
      <el-col
        :xs="24"
        :sm="24"
        :md="20"
        :lg="20"
        :xl="20"
        :class="{ 'navbar-wrapper-hide': isNavbarHide }"
        style="padding: 0px; float: right"
      >
        <!-- <el-col :xs="24" :sm="24" :md="21" :lg="21" :xl="21" :class="{'navbar-wrapper-hide': isNavbarHide}" style="padding: 0px; float: right;"> -->
        <div class="body_wrapper">
          <div class="content_wrapper">
            
            <Header
              @getChildNavbarCollapsedData="
                handleChildNavbarCollapsedData($event)
              "
              @getChildNavbarShowData="handleChildNavbarShowData($event)"
              @isShow="isShow($event)"
              :isShow="isShow"
            />
            <div class="topbar-fixed">
              <AppMain />
            </div>
          </div>
          <!-- chat bar  -->
          <!-- <Chat /> -->
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import AppMain from "./components/AppMain.vue";
import Navbar from "./components/Navbar.vue";
import Header from "./components/Header.vue";
// import Chat from "./components/Chat.vue";

export default {
  name: "Layout",
  components: {
    AppMain,
    Navbar,
    Header,
    // Chat,
  },
  props: {},
  data() {
    return {
      isNavbarCollapsed: false,
      isNavbarShow: true,
      isNavbarHide: false,
      isShow: null,
    };
  },
  computed: {},
  created() {},
  methods: {
    // //////////////////
    // EMIT METHODS
    // //////////////////
    handleChildNavbarCollapsedData(data) {
      console.log(data);
      if (data) {
        this.isNavbarCollapsed = !this.isNavbarCollapsed;
        console.log(this.isNavbarCollapsed);
        this.isShow = !this.isShow;
      }
    },
    handleChildNavbarShowData(data) {
      console.log(data);
      if (data) {
        this.isNavbarShow = !this.isNavbarShow;
        this.isNavbarHide = !this.isNavbarHide;
        console.log(this.isNavbarShow);
        console.log(this.isNavbarHide);
      }
    },
  },
};
</script>



<style scoped>
.sidebar-collapsed {
  width: 0px;
}
</style>

