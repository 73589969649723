import Vue from 'vue'
import App from './App.vue'
import router from "@/router/router.js"
import store from '@/store'
import ElementUI from 'element-ui';
import '@/assets/scss/element-variables.scss';

import VueSocketIO from 'vue-socket.io'
import SocketIO from 'socket.io-client'
import { _ } from 'vue-underscore'
import '@/icons' // icons
import '@/error-log' // error log

import 'element-ui/lib/theme-chalk/index.css';
import locale_en from 'element-ui/lib/locale/lang/en';
// CSS
import 'vue2-timepicker/dist/VueTimepicker.css'
// MAPGETTERS
import { mapGetters } from 'vuex'

// Moment Timezone
import momentTimezone from 'moment-timezone'
let timeZone = "Asia/Kolkata";
momentTimezone.tz.setDefault(timeZone);
import Cookies from 'js-cookie'
import getRoleSlug from "@/store/app.js";
import IdleVue from 'idle-vue'
import soundVariable from '@/assets/mixkit-confirmation-tone-2867.wav'; // Correct path
/**
 Vue Idle
 */
 
 const eventsHub = new Vue()
 Vue.use(IdleVue, {
   eventEmitter: eventsHub,
   idleTime: 600000, // miliseconds  600000
   store,
   startAtIdle: false
 })
Vue.use(ElementUI,{locale:locale_en});

// ///////////////////////
// SOCKET-IO
// ///////////////////////
const socketConnection = SocketIO(getRoleSlug.state.siteConfig.socketUrl);
//const socketConnection = SocketIO('http://localhost:8082');

Vue.use(new VueSocketIO({
    //debug: true,
    connection:socketConnection
  })
);


/*Vue.use(new VueSocketIO({
  debug: true,
  connection: 'http://api.volumeupdown.com:8082',
  vuex: {
      store,
      actionPrefix: 'SOCKET_',
      mutationPrefix: 'SOCKET_'
  },
  //options: {  } //Optional options
}))*/


// Vue.use(new VueSocketIO({
//   debug: true,
//   connection: 'http://localhost:8081',
//   // vuex: {
//   //     store,
//   //     actionPrefix: 'SOCKET_',
//   //     mutationPrefix: 'SOCKET_'
//   // },
//   // options: { path: "/my-app/" } //Optional options
// }))



// ///////////////////////
// MIXIN
// ///////////////////////
Vue.mixin({
  /* ************************ */
  /* GLOBALLY DECLARED FILTER */
  /* ************************ */
  filters: {
  },

  data() {
    return {

    }
  },

  computed: {
    ...mapGetters([
      'userInfo'
    ]),
  },

  /* ************************ */
  /* GLOBALLY DECLARED METHODS */
  /* ************************ */
  methods: {
    baseUrlApi() {
      return process.env.VUE_APP_BASE_API;
    },

    getFormatedDate(date,f)
    {
      if(!f)
      {
        f = "MMMM DD YYYY";
      }
      let changeDate = momentTimezone.utc(date).format(f);
      return changeDate;
    },

    today(f) {
      if (!f) f = 'YYYY-MM-DD';
      return momentTimezone().format(f);
    },

    getTimeZone() {
      return this.userInfo.timezone ? this.userInfo.timezone : 'Asia/kolkata';
    },

    showPwd(type) {
      if (type === 'password') {
        if (this.passwordType === 'password') {
          this.passwordType = 'text'
        } else {
          this.passwordType = 'password'
        }
      } else {
        if (this.confirmPasswordType === 'password') {
          this.confirmPasswordType = 'text'
        } else {
          this.confirmPasswordType = 'password'
        }
      }
    },

      /*
     * Will display success message
     */
      flashSuccess: function (message) {
        if (message) {
          this.$notify.success({
            title: 'Success',
            message: message
          })
        }
      },
      /*
       * Will display error message
       */
      flashError: function (message) {
        this.$notify.error({
          title: 'Error',
          message: message || 'Oops, something went wrong!'
        })
      },
      /*
       * Will display info message
       */
      flashInfo: function (message) {
        this.$notify.info({
          title: 'Info',
          message: message || 'Please check details information.'
        })
      },
      handleFilePreview(file) {
        file = JSON.parse(JSON.stringify(file))
        const fileName = file.name
        if (fileName && fileName.substr(fileName.length - 3) === 'pdf') {
          window.open(file.url)
        } else {
          window.open(file.url)
        }
      },
      isUrl(url) {
        try {
          url = new URL(url);
        } catch (_) {
          return false;
        }
        return url.protocol === "http:" || url.protocol === "https:";
      },
  
      isImgUrl(url) {
        if (this.isUrl(url)) {
          let arr = url.split('.');
          let c = arr.length;
          if (c > 0) {
            if (_.contains(this.getAllowedImgExtensions(), arr[c - 1])) {
              return true;
            }
            console.log('eee', arr[c - 1]);
          }
        }
        return false;
      },
      getAllowedImgExtensions() {
        return this.allowedImgExt;
      },
      getExt(url) {
        if (url) {
          const f = url;
          const fArr = f.split('.');
          const ext = fArr[fArr.length - 1];
          return ext;
        }
        return
      },

      playSound() {
        // const sound = 'http://localhost:8888/zurra/api.zurra.com/public/ding.mp3'
        const sound = soundVariable //'https://api.stage.zurra.com/ding.mp3'
        //http://localhost:8080/img/logo.821c69bb.png
        const w = window.open(sound, '_blank', 'width=10, height=10, top=0,left=-0')
        setTimeout(function() {
          if (w) w.close()
        }, 2000)
        // if (sound) {
        //   var audio = new Audio(sound)
        //   audio.play()
        //   // var sourceObject = HTMLMediaElement.srcObject;
        //   // HTMLMediaElement.srcObject = sourceObject;
        //   // HTMLMediaElement.play()
        // }
      },

    
      /**
       * Convert file to base64
       */
      toBase64(file){
           return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
      }
  }
})


Vue.config.productionTip = false

new Vue({
  onIdle() {
   
   let token = Cookies.get('Admin-Token')
   console.log("dsdfgyewrtey    256", token)
   if(token !== undefined){
    Cookies.remove('Admin-Token')
    if(getRoleSlug.state.roleSlug == 'superadmin') {
      this.$router.push({
        path: "/super-admin/",
      });
    } else {
        this.$router.push({
          path: "/" + getRoleSlug.state.roleSlug + "/",
        });
    }

   }
  
  },
  onActive() {
    // Last time active
    console.log("  onActive  ")
  },
  store,
  router,
  render: h => h(App),
}).$mount('#app')
