import Vue from "vue";
import Router from 'vue-router'


/* Layout */
import Layout from "@/layout/Layout.vue"
import SubRoute from "@/layout/components/SubRoute.vue"

import { adminRoutes } from "./admin_router"
import { brokerRoutes } from "./broker_router"
import {superAdminRoutes } from "./super_admin_router"
// import setUserLogin from '@/store/app.js'


Vue.use(Router);



const commonRoutes = [
    {
        path: '/admin',
        name: 'admin',
        meta: { title: 'Login', icon: 'user' },
        component: () => import('@/views/login/AdminLogin.vue'),
        hidden_sub_navbar: true,
    },
    {
        path: '/admin/dashboard',
        component: Layout,
        redirect: '/admin/dashboard',
        name: 'admin',
        hidden: false,
        meta: { title: 'Admin Dashboard', icon: 'user' },
        hidden_sub_navbar: false,
		children: [
            {
				path: 'admin/dashboard',
				name: 'admin/dashboard',
                component: SubRoute,
				meta: { title: 'admindashboard', icon: 'user' },
				hidden_sub_navbar: false,
				children: adminRoutes,
			},
        ]
    },
    {
        path: '/broker',
        name: 'broker',
        meta: { title: 'Login', icon: 'user' },
        component: () => import('@/views/login/BrokerLogin.vue'),
        hidden_sub_navbar: true,
    },
    {
        path: '/broker/dashboard',
        component: Layout,
        redirect: '/broker/dashboard',
        name: 'broker',
        hidden: false,
        meta: { title: 'Broker Dashboard', icon: 'user' },
        hidden_sub_navbar: false,
		children: [
            {
				path: 'broker/dashboard',
				name: 'broker/dashboard',
                component: SubRoute,
				meta: { title: 'brokerdashboard', icon: 'user' },
				hidden_sub_navbar: false,
				children: brokerRoutes,
			},
        ]
    },
    {
        path: '/super-admin',
        name: 'superadmin',
        meta: { title: 'Login', icon: 'user' },
        component: () => import('@/views/login/SuperAdminLogin.vue'),
        hidden_sub_navbar: true,
    },
    {
        path: '/super-admin/dashboard',
        component: Layout,
        redirect: '/super-admin/dashboard',
        name: 'superadmin',
        hidden: false,
        meta: { title: 'Super-Admin Dashboard', icon: 'user' },
        hidden_sub_navbar: false,
		children: [
            {
				path: 'super-admin/dashboard',
				name: 'super-admin-dashboard',
                component: SubRoute,
				meta: { title: 'super-admindashboard', icon: 'user' },
				hidden_sub_navbar: false,
				children: superAdminRoutes,
			},
        ]
    },

    {
        path: '/hit-count',
        name: 'hitcount',
        meta: { title: 'hitcount', icon: 'user' },
        component: () => import('@/views/login/hitcount.vue'),
        hidden_sub_navbar: false,
    },


]

// concating all routes into one
export const constantRoutes = commonRoutes;

const createRouter = () => new Router({
    mode: 'history', // require service support
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes
})

const router = createRouter()

// authenticating: restrict user from page to load if authRequires  = true or not set,
// to disable auth: authRequires = false in meta
// router.beforeEach((to, from, next) => {
// 	let authRequires = true; // initially auth required

// 	if ('authRequires' in to.meta) {
// 		authRequires = to.meta.authRequires // if key available then act accordingly
// 	}

// 	if (authRequires && !getToken()) {
// 		next({ path: '/' })
// 	}
// 	else {
// 		next()
// 	}
// })

export function resetRouter() {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher // reset router
}

export default router

