import Cookies from 'js-cookie'

const TokenKey = 'Admin-Token'
const LocationTokenKey = 'Location-Token'
const LangKey = 'Admin-Lang'
const FirebaseTokenKey = 'Firebase-Token'
const userInfoTokenKey = 'userInfo'
const userIdKey = 'userId'
const RoleSlugKey = 'roleSlug'


export function getRoleSlug() {
  return Cookies.get(RoleSlugKey)
}
export function removeRoleSlug() {
  return Cookies.remove(RoleSlugKey)
}

// SITE
export function getToken() {
  return Cookies.get(TokenKey)
}
export function setToken(token) {
  return Cookies.set(TokenKey, token)
}
export function removeToken() {
  return Cookies.remove(TokenKey)
}

// for location token
export function getLocationToken() {
  return Cookies.get(LocationTokenKey)
}
export function setLocationToken(token) {
  return Cookies.set(LocationTokenKey, token)
}
export function removeLocationToken() {
  return Cookies.remove(LocationTokenKey)
}

// for location token
export function getUserInfo() {
  return Cookies.get(userInfoTokenKey)
}
export function setUserInfo(data) {
  return Cookies.set(userInfoTokenKey, data)
}
export function setUserId(id) {
  return Cookies.set(userIdKey, id)
}
// export function removeLocationToken() {
//   return Cookies.remove(userInfoTokenKey)
// }


export function setLangDropdown(lang) {
  return Cookies.set(LangKey, JSON.stringify(lang))
}
export function getLangDropdown() {
  if (Cookies.get(LangKey)) {
    return JSON.parse(Cookies.get(LangKey))
  } else {
    return []
  }
}

// FIREBASE
export function getFirebaseToken() {
  return Cookies.get(FirebaseTokenKey)
}

export function setFirebaseToken(token) {
  return Cookies.set(FirebaseTokenKey, token)
}

export function removeFirebaseToken() {
  return Cookies.remove(FirebaseTokenKey)
}

export function getSubdomain() {
  const hostname = window.location.hostname;
  let subdomain;
  if (hostname.indexOf('.') !== -1) {
    subdomain = hostname.split('.')[1];
  } else {
    subdomain = "localhost";
  }
  return subdomain;
}
