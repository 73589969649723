<template>
  <svg :class="svgClass" :style="svgStyle">
    <use :href="svgName" />
  </svg>
</template>

<script>
export default {
  name: 'SvgIcon',
  props: {
    iconName: {
      type: String,
      required: true
    },
    iconClass: {
      type: String,
      default: ''
    },
    iconStyle: {
      type: String,
      default: ''
    },
    iconAnimation: {
      type: String,
      default: '',
    },
  },
  computed: {
    svgName() {
      return `#${this.iconName}`
    },
    svgClass() {
      let c = 'svg-icon'
      if (this.iconClass) {
        c += ' ' + this.iconClass
      }
      if (this.iconAnimation) {
        c += ' ' + this.iconAnimation
      }
      return c
    },
    svgStyle() {
      return `${this.iconStyle}`
    },
  }
}
</script>

<style scoped>
svg.svg-icon {
  width: 1em;
  height: 1em;
  vertical-align: middle;
  fill: currentColor;
  overflow: hidden;
}
svg.svg-icon-medium {
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
}
svg.svg-icon-large {
  width: 3em;
  height: 3em;
}
svg.svg-icon-xlarge {
  width: 5em;
  height: 5em;
}
svg.spin {
  animation: icon-spin 2s infinite linear;
}
@keyframes icon-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

svg.shake {
  animation: icon-shake 2s infinite linear;
}
@keyframes icon-shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}

svg.color-change {
  animation: icon-color-change 2s infinite linear;
}
@keyframes icon-color-change {
  0%   {color:red;}
  25%  {color:yellow;}
  50%  {color:blue;}
  75%  {color:green;}
  100% {color:red;}
}
</style>
