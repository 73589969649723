import { _ } from "vue-underscore";


export function hasPermission(permissions, permissions_array) { // module, feature, feature_actions
	if (!_.isEmpty(permissions)) {
		let m = permissions_array[0] ?? null;
		let f = permissions_array[1] ?? null;
		let fa = permissions_array[2] ?? null;
		if (m && f && fa) { // feature actions
			if (m in permissions.modules) {
				if (f in permissions.modules[m].features) {
					if (permissions.modules[m].features[f].feature_actions.includes(fa)) {
						return true; // has permission
					}
				}
			}
			return false; // no permission
		}
		else if (m && f) { // features
			if (m in permissions.modules) {
				if (f in permissions.modules[m].features) {
					return true; // has permission
				}
			}
			return false; // no permission
		}
		else if (m) { // only module
            if(m in permissions.modules){
                return true; // has permission
            }

            return false;
		}

		return false; // no permission
	}

	return false; // no permission
}