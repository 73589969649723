// import { getDeviceInfo, fetchPermissions, getUserInfo, getRefreshCount, getRefreshCountMessages, loginByUsername, logout, setDeviceTokenWeb, reLogin } from '@/api/login'
import { getDeviceInfo, getRefreshCount,getUserInfo, getRefreshCountMessages, loginByUsername, setDeviceTokenWeb, reLogin } from '@/api/login'
import { fetchList } from "@/api/action-leager";
// , getFirebaseToken, removeFirebaseToken, setFirebaseToken
import {setUserId } from '@/utils/auth'
import { getToken, removeToken, setToken, setLangDropdown, getLangDropdown, getLocationToken, setLocationToken, removeLocationToken } from '@/utils/auth'
import { isMobileOnly, isTablet, isBrowser, isSmartTV, isWearable, isConsole, isAndroid, isWinPhone, isIOS, osName, osVersion, browserName, fullBrowserVersion } from 'mobile-device-detect'
import Vue from 'vue'
import { constantRoutes } from '@/router/router.js'

import { hasPermission } from '@/utils/permissions.js'

// import Cookies from 'js-cookie'
const user = {
	state: {
		lng: 'en',
		user: '', // Object with full details
		token: getToken(),
		location_token: getLocationToken(),
		location: '', // Object with full details
		role: '', // Object with full details
		refresh_count: '',  // Object with full details
		refresh_count_messages: '',  // Object with full details
		permissions: {}, // permissions fetched from api
		user_info: {},// complete user details
		lang_dropdown: getLangDropdown(),// complete lang dropdown
		constant_routes: [], // impoirted array of routes
		page_refreshing: true,
		history_info:[]
	},

	mutations: {
		SET_TOKEN: (state, token) => {
			state.token = token
		},
		SET_LOCATION_TOKEN: (state, token) => {
			state.location_token = token
		},
		SET_LANG_DROPDOWN: (state, lang_dropdown) => {
			state.lang_dropdown = lang_dropdown
		},
		SET_USER: (state, user) => {
			state.user = user
		},
		SET_ROLE: (state, role) => {
			state.role = role
		},
		SET_LOCATION: (state, location) => {
			state.location = location
		},
		SET_REFRESH_COUNT: (state, refresh_count) => {
			state.refresh_count = refresh_count
		},
		SET_REFRESH_COUNT_MESSAGES: (state, refresh_count_messages) => {
			state.refresh_count_messages = refresh_count_messages
		},
		SET_PERMISSIONS: (state, permissions) => {
			state.permissions = permissions
		},
		SET_USER_INFO: (state, info) => {
			state.user_info = info

			// console.log(info)
		},
		SET_HISTORY_INFO: (state, info) => {
			state.history_info = info;
			console.log('set info',state)
		},
		SET_CONSTANT_ROUTES: (state) => {
			let constant_routes_local = constantRoutes;

			// for all permissions: layer 1
			if (constant_routes_local) {
				constant_routes_local.forEach(parent => {
					if ('permissions' in parent) { // if permissions availabel
						if (parent.permissions.length > 0) {
							parent.hidden_sub_navbar = !hasPermission(state.permissions, parent.permissions);
						}
					}
					// for configurations navingations
					let is_configuration_router = false;
					let is_configuration_router_has_permission = false;
					if (parent.name == "configurations" || parent.name == "configuration") {
						parent.hidden_sub_navbar = true;
						is_configuration_router = true;
					}

					// layer 2
					if ('children' in parent) {
						parent.children.forEach(children1 => {
							if ('permissions' in children1) { // if permission available
								if (children1.permissions.length > 0) {
									children1.hidden_sub_navbar = !hasPermission(state.permissions, children1.permissions)
								}
							}

							// layer 3
							if ('children' in children1) {
								children1.children.forEach(children2 => {
									if ('permissions' in children2) { // if permission available
										if (children2.permissions.length > 0) {
											children2.hidden_sub_navbar = !hasPermission(state.permissions, children2.permissions)
											// for configurations navingations
											if (is_configuration_router && !children2.hidden_sub_navbar) {
												is_configuration_router_has_permission = true;
											}
										}
									}
								})
							}
						})
					}

					// for configurations navigations
					if (is_configuration_router && is_configuration_router_has_permission) {
						parent.hidden_sub_navbar = false;
					}
				})

				// changing states
				state.constant_routes = constant_routes_local;
			}
		},
	},
	getters: {
		permissions(state) {
			return state.permissions
		},
		userInfo(state) {
			setUserId(state.user_info.id)
			return state.user_info;
		},
		historyInfo(state) {
			console.log("get history",state.history_info)
			return state.history_info;
		},
		langDropdown(state) {
			return state.lang_dropdown;
		},
		constantRoutes(state) {
			if (state.page_refreshing) {
				return [];
			}
			return state.constant_routes;
		}
	},
	actions: {
		// Login by username
		LoginByUsername({ commit }, userInfo) {
			const username = userInfo.username.trim()
			// const role = userInfo.role.trim()
			// const subdomain = userInfo.subdomain.trim()
			// const language = userInfo.language.trim()
			return new Promise((resolve, reject) => {
				// Get device info
				// const deviceInfo = getDeviceInfo(isMobileOnly, isTablet, isBrowser, isSmartTV, isWearable, isConsole, isAndroid, isWinPhone, isIOS, osName, osVersion, browserName, fullBrowserVersion)

				// make login data set
				let data = {
					username: username,
					password: userInfo.password,
					// role: role,
					// subdomain: subdomain,
					// device_info: deviceInfo,
					// language: language,
					// check_already_logged_in: true
				}

				// // Lets fire login with device info
				// getIp().then(response => {
				//   data.device_info = Object.assign(data.device_info, { ip_address: response.data.ip })

				// Lets fire login with device info
				loginByUsername(data).then(response => {
					if (response.data.success === true) {
						let responseData = response.data.data
						let go = true

						// /////////////////////////////////////////////////////
						// NOTE:
						// CHECK IF SOMEONE ALREADY LOGGED IN WITH SAME USER ID
						// /////////////////////////////////////////////////////
						if (responseData.already_logged_in) {
							go = false
							Vue.prototype.$confirm('Someone is already logged to the system using this account. Would you like to log them out?', 'Warning', {
								confirmButtonText: 'Yes',
								cancelButtonText: 'No',
								type: 'warning'
							}).then(() => {
								data = Object.assign(data, { check_already_logged_in: false })
								loginByUsername(data).then(response => {
									if (response.data.success === true) {
										responseData = response.data.data
										// user INFO
										// commit('SET_USER_INFO', responseData.user);
										// setUserInfo("set user info")
										// console.log(responseData.user)
										// SITE TOKEN
										commit('SET_TOKEN', responseData.access_token)
										setToken(responseData.access_token)
										// commit('SET_LANG_DROPDOWN', responseData.languages)
										// setLangDropdown(responseData.languages)
										// first remove exysting location token then set location token
										removeLocationToken()
										if (responseData.location_token) {
											commit('SET_LOCATION_TOKEN', responseData.location_token)
											setLocationToken(responseData.location_token)
										}
										// FIREBASE TOKEN
										// commit('SET_FIREBASE_TOKEN', responseData.firebase_token)
										// setFirebaseToken(responseData.firebase_token)

										// // Firebase Authenticate token
										// const firebaseToken = responseData.firebase_token
										// if (firebaseToken) {
										//   firebase.auth().signInWithCustomToken(firebaseToken)
										//     .then((user) => {
										//       // console.log('Firebase auth successful', firebaseToken)
										//     })
										//     .catch((error) => {
										//       var errorCode = error.code
										//       var errorMessage = error.message
										//       console.log('Firebase auth error:', errorCode, errorMessage)
										//     })
										// }
										resolve()
									}
								})
							}).catch(() => {
								go = false
								reject()
							})
						}

						if (go) {
							// SITE TOKEN
							commit('SET_TOKEN', responseData.access_token)
							setToken(responseData.access_token)
							// commit('SET_LANG_DROPDOWN', responseData.languages)
							// setLangDropdown(responseData.languages)
							// first remove exysting location token then set location token
							removeLocationToken()
							// user INFO
							// commit('SET_USER_INFO', responseData.user);
							// setUserInfo(responseData.user)
							// console.log(responseData.user)
							if (responseData.location_token) {
								commit('SET_LOCATION_TOKEN', responseData.location_token)
								setLocationToken(responseData.location_token)
							}



							// // FIREBASE TOKEN
							// commit('SET_FIREBASE_TOKEN', responseData.firebase_token)
							// setFirebaseToken(responseData.firebase_token)

							// // Firebase Authenticate token
							// const firebaseToken = responseData.firebase_token
							// if (firebaseToken) {
							//   firebase.auth().signInWithCustomToken(firebaseToken)
							//     .then((user) => {
							//       // console.log('Firebase auth successful', firebaseToken)
							//     })
							//     .catch((error) => {
							//       var errorCode = error.code
							//       var errorMessage = error.message
							//       console.log('Firebase auth error:', errorCode, errorMessage)
							//     })
							// }
							resolve()
						}
					} else {
						Vue.prototype.$message({
							message: response.data.message,
							type: 'error',
							duration: 1500
						})
						reject()
					}
				}).catch(error => {
					reject(error)
				})
				// }).catch(error => {
				//   reject(error)
				// })
			})
		},

		// navbar loaded or refreshed : both cases fired
		pageLoadedORRefreshed({ commit, state }) {
		// pageLoadedORRefreshed({ state }) {
			state.page_refreshing = true;
			// Get user info
			getUserInfo({}).then((response) => {
				commit('SET_USER_INFO', response.data.data.user);
				commit('SET_PERMISSIONS', response.data.data.userConfig);
			})

			// getting permissions and re-assign routes permissions
			// fetchPermissions({}).then((response) => {
			// 	state.page_refreshing = false;
			// 	commit('SET_PERMISSIONS', response.data.data);
			// 	commit('SET_CONSTANT_ROUTES'); // re-assign routes permissions
			// });
		},
		// navbar loaded or refreshed : both cases fired
		getHistory({ commit, state }) {
		// pageLoadedORRefreshed({ state }) {
			state.page_refreshing = true;
			// Get user info
			fetchList({}).then((response) => {
				commit('SET_HISTORY_INFO', response.data.data.data);
			})
		},
		

		// Get refresh count
		GetRefreshCount({ commit }, query) {
			return new Promise((resolve, reject) => {
				getRefreshCount(query).then(response => {
					if (!response.data) { // Since mockjs does not support custom status codes, it can only be hacked like this
						reject('error')
					}
					const data = response.data.data
					commit('SET_REFRESH_COUNT', data.data)

					resolve(response)
				}).catch(error => {
					reject(error)
				})
			})
		},

		// Get refresh count for messages
		GetRefreshCountMessages({ commit }) {
			return new Promise((resolve, reject) => {
				getRefreshCountMessages().then(response => {
					if (!response.data) { // Since mockjs does not support custom status codes, it can only be hacked like this
						reject('error')
					}
					const data = response.data.data
					commit('SET_REFRESH_COUNT_MESSAGES', data.data)

					resolve(response)
				}).catch(error => {
					reject(error)
				})
			})
		},

		// Logout
		LogOut() {
			// return new Promise((resolve, reject) => {
			return new Promise((resolve) => {
				// const deviceInfo = getDeviceInfo(isMobileOnly, isTablet, isBrowser, isSmartTV, isWearable, isConsole, isAndroid, isWinPhone, isIOS, osName, osVersion, browserName, fullBrowserVersion)
				// commit('SET_TOKEN', '');
				removeToken();
				// removeRoleSlug();
				resolve(true)
				
				// removeLocationToken();
				// logout({ 'device_info': deviceInfo }).then((response) => {
				// 	commit('SET_TOKEN', '')
				// 	// commit('SET_LOCATION_TOKEN', '')
				// 	// commit('SET_FIREBASE_TOKEN', '')
				// 	// commit('SET_ROLE', '')
				// 	removeToken()
				// 	removeLocationToken()
				// 	// removeFirebaseToken()
				// 	resolve(response)
				// }).catch(error => {
				// 	console.log(error)
				// 	reject(error)
				// })
				// .finally(()=>{

				// })
			})

			//below old code : might be used: don't know yet

			// return new Promise((resolve, reject) => {
			// 	// Get device info
			// 	const deviceInfo = getDeviceInfo(isMobileOnly, isTablet, isBrowser, isSmartTV, isWearable, isConsole, isAndroid, isWinPhone, isIOS, osName, osVersion, browserName, fullBrowserVersion)

			// 	// // Lets fire login with device info
			// 	// getIp().then(response => {
			// 	//   console.log('response', response)
			// 	//   device_info = Object.assign(device_info, { ip_address: response.data.ip })

			// 	logout({ 'device_info': deviceInfo }).then((response) => {
			// 		console.log(response)
			// 		commit('SET_TOKEN', '')
			//		commit('SET_LOCATION_TOKEN', '')
			// 		// commit('SET_FIREBASE_TOKEN', '')
			// 		commit('SET_ROLE', '')
			// 		removeToken()
			//		removeLocationToken()
			// 		// removeFirebaseToken()
			// 		this.$router.push({
			// 			path: this.redirect || "/",
			// 		});
			// 		resolve()
			// 	}).catch(error => {
			// 		reject(error)
			// 	})
			// 	// }).catch(error => {
			// 	//   reject(error)
			// 	// })
			// })
		},

		// Keep Logged In
		ReLogin() {
			return new Promise((resolve, reject) => {
				// Get device info
				const deviceInfo = getDeviceInfo(isMobileOnly, isTablet, isBrowser, isSmartTV, isWearable, isConsole, isAndroid, isWinPhone, isIOS, osName, osVersion, browserName, fullBrowserVersion)

				reLogin({ 'device_info': deviceInfo }).then(() => {
					resolve()
				}).catch(error => {
					reject(error)
				})
			})
		},

		SetDeviceTokenWeb(data) {
			return new Promise((resolve, reject) => {
				// Get device info
				setDeviceTokenWeb({ device_token_web: data }).then(response => {
					if (!response.data) {
						reject('error')
					} else {
						resolve()
					}
				})
			})
		},

		// Front end
		FedLogOut({ commit }) {
			return new Promise(resolve => {
				commit('SET_TOKEN', '')
				commit('SET_LOCATION_TOKEN', '')
				removeToken()
				removeLocationToken()
				resolve()
			})
		},

		// IS LOGOUT AUTOMATICALLY
		isLogoutAutomatically({ commit }, data) {
			return new Promise(resolve => {
				commit('SET_IS_LOGOUT_AUTOMATICALLY', data)
				resolve()
			})
		},

		// from api settings, set to language dropdwon
		SetLanguageDropdwon({ commit }, languages) {
			commit('SET_LANG_DROPDOWN', languages)
			setLangDropdown(languages)
		}
	}
}


export default user
